<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div
      @mousedown.stop
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
    ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Preview</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="ratio ratio-16x9">
            <iframe
              v-if="videoSource === 'youtube'"
              :src="youtubeIframeSrc"
              class="embed-responsive-item"
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              v-if="videoSource === 'vimeo'"
              :src="vimeoIframeSrc"
              class="embed-responsive-item"
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <video
              v-if="videoSource === 'upload'"
              :src="videoUrl"
              controls
              playsinline
              preload="metadata"
            ></video>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="close"
            type="button"
            class="btn btn-primary"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoProvider from '@/core/videoProvider';

export default {
  name: 'ResourcePreviewModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: Object,
    },
    url: {
      type: String,
    },
  },
  computed: {
    videoUrl() {
      const { resource, url } = this;
      return resource ? resource.url : url;
    },
    videoSource() {
      const u = this.videoUrl;
      if (u.indexOf('vimeo.com') > -1) return 'vimeo';
      if (u.indexOf('youtube.com') > -1) return 'youtube';
      if (u.indexOf('youtu.be') > -1) return 'youtube';
      return 'upload';
    },
    vimeoIframeSrc() {
      const videoId = VideoProvider.getVimeoId(this.videoUrl);
      return `https://player.vimeo.com/video/${videoId}`;
    },
    youtubeIframeSrc() {
      const videoId = VideoProvider.getYouTubeId(this.videoUrl);
      return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&modestbranding=1&playsinline=1`;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
